.StatBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px;
}

.StatBody__charts{
    display: flex;
    background: rgba(252,187,126,0.2);
    width: 47%;
    min-height: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}
.StatBody__CamembertChart{
    /* background: #e6e6e6;
    border-radius: 4px;
    padding: 19px 0px 29px 0px;
    height: 150px !important;
    width: 226px !important; */
    display: flex;
    background: #e6e6e6;
    min-height: 307px;
    flex-direction: column;
    border-radius: 10px;
    width: 49%;
}
.StatBody__CamembertChart canvas{
    height: 249px !important;
    width: 527px !important;
}

.StatBody__report canvas{
    width: 600px !important;
    height: 264px !important;
}


.StatBody__report{
    display: flex;
    background: white;
    min-height: 350px;
    width: 46%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}

.intervalBtn{
    display: flex;
    margin: 20px;
    padding: 10px 20px;
    justify-self: self-end;
    background-color: #ea5e0b;
    border: 2px solid #ea5e0b;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.intervalBtn:hover{
    transition: 0.2s all ease;
    background-color: white;
    color: #ea5e0b;
}
.intervalBtn:focus{
    outline: none;
}