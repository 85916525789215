.previewDropDownMenu {
    position: relative;
    display: inline-block;
    cursor:pointer;
}

.previewIcon {
    border: 1px solid gray;
    border-radius: 5px;
    font-size: 1rem;
}

.previewDropDownMenuContent {
    position:absolute;
    min-width:200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index:1;
    display:none;
    background-color: white;
}

.previewDropDownMenu:hover .previewDropDownMenuContent {
    display:block    
}