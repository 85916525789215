.referentiel__container{
    width: 100%;
    height: 100vh;
}

.referentiel__zonePrincipal{
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.referentiel__zoneSecondaire{
    display: flex;
    justify-content: space-between;
    /*margin-top: 25px;*/
}
.referentiel__zoneSecondaireA{
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}
.referentiel__zoneSecondaireB{
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.referentiel__zoneTitle{
    padding: 5px;
    border-bottom: 1px solid rgb(38, 146, 235);
    margin-bottom: 15px;
}
.referentiel__zoneTitle h2{
    font-size: 1.1rem;
    font-weight: 600;
    color: #21302f;
    margin-bottom: 0;
}

.referentiel__element{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 10px 10px;
}
.referentiel__element_forCc{
    margin: 10px 0 10px 10px;
    display: flex;
}
.referentiel__element_forCc h5{
    font-size: 14px;
}
.referentiel__element_forCc div{
    margin-top: -2px;
    display: grid;
    margin-left: 10px;
}
.referentiel__element_forCc div span{
    display: list-item;
}
.referentiel__element_childrens{
    align-items: center;
    margin: 10px 0 10px 10px;
}
.referentiel__element_childrens h5{
    font-size: 14px;
    font-weight: "bold";
    margin-right: "20px"
}

.referentiel__element h5 {
    font-size: 14px;
}

.referentiel__backBtn{
    display: flex;
    margin: 20px 0px;
    padding: 10px 20px;
    justify-self: self-end;
    background-color: rgb(25, 98, 158);
    border: 2px solid rgb(25, 98, 158);
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.referentiel__backBtn:hover{
    transition: 0.2s all ease;
    background-color: white;
    color: rgb(25, 98, 158);
}
.referentiel__backBtn:focus{
    outline: none;
}