.userNavigation{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 1rem;
    height: 80px;
    box-shadow: 0px 6px 10px -3px rgba(0, 0, 0, 0.13);
}
.userNavigation__container{
    display: flex;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 0 24px;
    /* max-width: 1100px; */
}

.userNavigation__center{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80px;
}

.userNavigation__nav__menu{
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
}

.userNavigation__nav__item{
    height: 80px;
}

.userNavigation__nav__item:hover {
    border-bottom: 3px solid rgb(38, 146, 235);
    text-decoration: none;
}

.userNavigation__nav__Link {
    color: #112732;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 5px;
}

.userNavigation__nav__Link:hover {
    border-bottom: 3px solid rgb(38, 146, 235);
    color: #0d7d65;
    text-decoration: none;
    background-color: white;
}

.userNavigation__nav__link_active {
    border-bottom: 3px solid rgb(38, 146, 235);
}

.userNavigation__right{
    display: flex;
    align-items: center;
    justify-content: center;
}

.userNavigation__right h5 {
    margin: 5px 10px 0 0;
    color: #112732;
    font-size: 1rem;
}

.userNavigation__profil{
    position: relative;
}

.userNavigation__profil span {
    align-self: baseline;
    font-size: 0.8rem;
    margin: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}

.userNavigation__nav__icon{
    color: #666666;
}
.userNavigation__nav__menu li .nav__icon{
    margin-right: 5px;
}

.userNavigation__toggleBtn{
    width: 40px;
    height: 40px;
    background-color: black;
    position: absolute;
    top: 20px;
    right: -20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.userNavigation__textTransitionHide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 1s linear;
}

.userNavigation__textTransitionShow{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 1s linear;
}
