.statHeaderElement__container{
    display: flex;
    /*width: 40%;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    /* border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5); */
    /* background-color: #112732; */

}

.row { margin-top:30px; }
.element__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.elementHeader__value {
    font-size: 1rem;
    color: #888888;
    font-weight: 600;
    /* color: white; */
}
.element__progressBar{
    margin: 15px 0;
    width: 100%;
    background: white;
    display: flex;
    justify-self: flex-start;
    justify-content: flex-start;
    height: 5px;
    border: 1px solid rgba(13,125,101,0.3);
    border-radius: 3px;
    overflow: hidden;

}
.element__progressBar span {
    height: 5px;
    display: flex;
    background: rgb(38, 146, 235);
    /* width: 90%; */
}

.element__body{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    /* color: white; */
}
