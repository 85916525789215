.headband {
    height: 50px;
    background: rgb(25, 98, 158);
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #DCDCDC;
}

.headband__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headband__visualisation {
    display: flex;
    align-items: center;
    background-color: white;
    height: 90%;
    margin-right: 10px;
}

.cssDropdown {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    right: 0;
    border-radius: 10px;
}

/* #handleHeader{
    visibility: hidden;
}
#handleHeader:hover{
    visibility: visible;
}
#personIcon:hover ~ #handleHeader{
    visibility: visible;
    animation: hide 1s 2s forwards;
}
#personIcon:hover ~ #handleHeader{
    visibility: visible;
    animation: hide 1s 2s forwards;
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
} */

.headbandVisualisation__profil {
    height: 100%;
    display: flex;
    padding: 0 1rem;
    border-right: 3px solid #666666;
    align-items: flex-end;
}

.headbandVisualisation__profils {
    height: 100%;
    display: flex;
    padding: 0 1rem 0.36rem;
    align-items: flex-end;
    color: white;
}

.headbandVisualisation__profils h5 {
    font-size: 1rem;
}

.headbandVisualisation__profils h5 span {
    font-weight: 600;
}

.headbandVisualisation__profil h5 {
    font-size: 1rem;
}

.headbandVisualisation__profil h5 span {
    font-weight: 600;
}

.headbandVisualisation__nav__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    text-align: center;
    height: 100%;
}

.headbandVisualisation__nav__item {
    height: 100%;
}

.headbandVisualisation__nav__item:hover {
    text-decoration: none;
}

.headbandVisualisation__nav__icon {
    color: rgb(38, 146, 235);
}

.headbandVisualisation__nav__Link {
    color: #112732;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 1rem;
    /* margin-top: 5px; */
}

.headbandVisualisation__nav__Link span {
    margin-top: 7px;
}

.headbandVisualisation__nav__Link:hover {
    text-decoration: none;
}

.headbandVisualisation__nav__link_active {
    background-color: rgb(38, 146, 235);
    border-bottom: 3px solid rgb(38, 146, 235);
    color: white;
}

.headbandVisualisation__nav__link_active .headbandVisualisation__nav__icon {
    color: white;
}

.headband__profil {
    position: relative;
    margin: 0;
    margin-right: 20px;
}

.profileDropdown {
    position: relative;
    margin: 0;
    margin-right: 20px;
}

.profileDropdown:hover .cssDropdown {
    display: block;
}

.dropdownLink {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    border-bottom: 0 none !important;
}


.headbandVisualisation__tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.headbandVisualisation__tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
}

.headbandVisualisation__tooltip:hover .tooltiptext {
    visibility: visible;
}