.tableBody__tr:nth-child(even){
    background-color: #ebebeb; 
}
.tableBody__tr{
    font-weight: 600;
    /* display: table;
    width: 100%;
    table-layout: fixed; */
    font-size: 12px;
    color: #666666;
}

.tableBody__tr_error{ 
    font-weight: 600;
    font-size: 12px;
    background-color: #f30000;
}

.tableBody__companyName{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableBody__Amount{
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
}
.form-check-input {
    position:inherit;
}
