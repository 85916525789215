.clientSettings__container{
    min-height: 692px;
    background: white;
    padding: 10px 0px 0px 0px;
    margin: 0 0px 0px 20px;
}

.form-control-sftp{
    display: inline-grid;
}
.active {
    border-bottom: 3px solid white;
}
.core-sftp{
    padding: 0px 25px 0px 49px;

}
.clientSettings__formH1{
    margin-top: -21px;
    color: #666666;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 10px 10px 20px;
}

.clientSettings__formLabel{
    margin-bottom: 9px;
    font-size: 14px;
    color: #666666;
    font-weight: 600;
    min-width: 92px;
    width : 520px;
    background-color: aliceblue
}
.clientSettings__formInput{
    padding: 4px 4px;
    margin-bottom: 20px;
    color: #666666;
    border:2px solid rgb(25, 98, 158) ;
    border-radius: 4px;
    font-size: 13px;
    width: 100%;
}

.nav-tabs .nav-link{ color: gray ; }
.nav-tabs .nav-link.active { color: white; background-color: rgb(25, 98, 158); font-weight: 600;}
.justify-top { margin-top: 29px ;}
.tab-content{
    width:800px;
    margin-top:30px;
}

.visible-icon {
    float: right;
    margin-right: 10px;
    margin-top: -48px;
    position: relative;
    cursor: pointer;
}