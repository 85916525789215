.userVisualisation{
    /* display: flex; */
    width: 100%;
}

.userVisualisation__Nav{
    position: sticky;
    /* height: 100vh; */
    top: 0;
    transition: all 1s ease;
}

.userVisualisation__body{
    margin-bottom: 20px;
    margin-top: 30px;
    width: 100%;
}