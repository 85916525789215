.caEnseigneCard__header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.caEnseigneCard__headerTitle{
    display: flex;
    align-items: flex-end;
}

.caEnseigneCard__headerPeriode h5{
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.caEnseigneCard__headerTitle h5 {
    font-size: 1rem;
    margin: 0;
}
.caEnseigneCard__periodeBtn{
    display: flex;
    align-items: center;
    margin: auto;
}