.StatBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px;
}

.StatBody__charts{
    display: flex;
    background: rgba(252,187,126,0.2);
    width: 47%;
    min-height: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}

.StatBody__report{
    display: flex;
    background: white;
    min-height: 500px;
    width: 47%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    margin:auto;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.StatBody__report select {
    margin-top:14px;
}

.intervalBtn{
    display: flex;
    margin: 20px;
    padding: 10px 20px;
    justify-self: self-end;
    background-color: #ea5e0b;
    border: 2px solid #ea5e0b;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.intervalBtn:hover{
    transition: 0.2s all ease;
    background-color: white;
    color: #ea5e0b;
}
.intervalBtn:focus{
    outline: none;
}
.card-header span {
    margin-right: 13px;
    vertical-align: middle;
}
.card-header span svg {
    color: #888888;
}

.comptaStatBody__cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.comptaStatBody__cardHeader__title {
    display: flex;
    align-items: flex-end;
}

.comptaStatBody__cardHeader__title h5{
    font-size: 1rem;
    margin: 0;
}