* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

.content {
  width:calc(100%);
}
.app {
  /* display: flex; */
  min-height: 100vh;
}

select { font-size:12px; }

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(214, 209, 209); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(204, 199, 199); 
}