
.admInfoCard__headerDropDownAction{ 
    width: 350px;
}
.emailSettings__container{
    min-height: 692px;
    background: white;
    padding: 10px 0px 0px 0px;
    margin: 0 0px 0px 20px;
}

.preview__emailDropdown:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 13px;
    transform: rotate(-1deg);
    border: 6px solid white;
    border-color: transparent transparent rgb(25, 98, 158) transparent;
}
.preview__emailDropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #ffffff;
    border: 1px solid rgb(25, 98, 158);
    top: 28px;
    left: 613px;
    /*left: -40px;*/
    min-width: 185px;
    padding: 0px 5px;
    border-radius: 5px;
    z-index: 200;
    font-size: small;
}

.mailPreview__DropDownActions {
    position: absolute;
    background-color: white;
    width: fit-content;
    border: 1px solid lightgray;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    left: 628px;
    top: 21px;
}


.urlWidth{
    width: 295px;
}
.form-control-mail{
    display: inline-grid;
}
.notice{
    min-width: 540px;
}
.labelSpec{ 
    margin-right: 40px;
}
.right{
    margin-right: 93px;
}
.left{
    margin-left: 40px;
    display: table-caption;
}
.mailPreview{
    cursor: pointer;
    color: rgb(25, 98, 158);
}

.formMailCustomer{
    margin: 8px;
    cursor: pointer;
}
.menuContainer{
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 75%;
}
.subMenu{
    margin-left:"10px"; 
    font-size: small;
}
.tags-left{margin-left: 40px;color: #666666;}
.active {
    border-bottom: 3px solid white;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    width: 250px;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: rgb(25, 98, 158);
    border-color: #b9c8ce;
}
.form-bloc{
    display: flex;
    justify-content: space-around;
}
.core-Mails{
    padding: 0px 25px 0px 49px;

}
.emailSettings__FromWrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.emailSettings__Icon{
    margin-left: 32px;
    margin-top: 32px;
}
.emailSettings__fromContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.emailSettings__form{
    background: #ffffff;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}
.emailSettings__formH1{
    margin-top: -21px;
    color: #666666;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 10px 10px 20px;
}

.emailSettings__error{
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 20px;

}
.emailSettings__formLabel{
    margin-bottom: 9px;
    font-size: 14px;
    color: #666666;
    font-weight: 600;
    min-width: 92px;
    width : 520px;
    background-color: aliceblue
}
.emailSettings__formInput{
    padding: 4px 4px;
    margin-bottom: 20px;
    color: #666666;
    border:2px solid rgb(25, 98, 158) ;
    border-radius: 4px;
    font-size: 13px;
}
.emailSettings__formInput:focus{
    outline: none;
}

.emailSettings__formButton{
    display: flex;
    margin: 20px 0px;
    padding: 10px 20px;
    justify-self: self-end;
    background-color: rgb(25, 98, 158);
    border: 2px solid rgb(25, 98, 158);
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.emailSettings__formButton:hover{
    transition: 0.2s all ease;
    background-color: white;
    color: rgb(25, 98, 158);
}
.emailSettings__formButton:focus{
    outline: none;
}

.nav-tabs .nav-link{ color: gray ; }
.nav-tabs .nav-link.active { color: white; background-color: rgb(25, 98, 158); font-weight: 600;}
.justify-top { margin-top: 29px ;}
.tab-content-setting{
    width:800px;
    margin-top:30px;
}

.logo {
    border: 4px grey double;
    width: 100%;
}

.logo-block {
    text-align: center;
    max-width: 150px;
    margin-left: 20px;
    display: inline-block;
}

.logo-block label {
    display: block;
}

.modalBody p {
    padding-left:5px ;
}
.modalBody p:hover {
    background-color: aliceblue;
}