.invoice{
    max-width: 100%;
    background: white;
}
.invoiceTable{
    width: 100%;
}

.invoiceHeader {
    background: #5ca1e5;
    padding: 7px 10px 8px 5px;
}
.table{
    width: 100%;
    table-layout: fixed;
    background-color: #fcfcfc;
}

.table th, .table td {
    padding:5px;
}
/* .table tbody {
    display: block;
    overflow: auto;
    max-height: calc(100vh - 88px);
} */

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:first-child {
    margin-top: 8px;
}

.invoiceTable__spinner{
    display: flex;
    justify-content: center;
    width: 100%
}

/* input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    padding: 3px;
    background-clip: content-box;
    border: 1.5px solid #21302f ;
    border-radius: 6px;
    background-color: #e7e6e7;
    padding: 0px;
    z-index: 0;
}
input[type=checkbox]:checked{background-color: rgb(248, 178, 75);}
input[type=checkbox]:focus{ cursor: pointer; outline: none;}
input[type=checkbox]:hover{cursor: pointer;} */

