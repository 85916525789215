.pagination{
    margin-bottom: 0;
    border-radius: 10% 0 0 10%;
}
.paginationContainer{
    display: flex;
    align-items: center;
    float: right;
    justify-content: flex-end;
    margin-top: 5px;
}
.pagination__total{
    display: flex;
    justify-self: flex-start;
}
.pagination__total h5 {
    font-size: 1rem;
}
.paginationList{
    display: flex;
    align-items: flex-end;
    justify-self: flex-end;
}
.paginationList >ul {
    align-items: center;
}
.paginationList  ul li a{
    padding: 5px;
    width: 40px;
    margin: auto;
    text-align: center;
}

.page-link{
    background-color: #f8f9fa;
    color:#3c3f43;
}
.page-link:hover {
    transition: all 0.2s ease-in-out;
    background-color: #dae0e5;
}
.page-item.active {
    border: none;
}

.page-item.active .page-link {
    transition: all 0.2s ease-in-out;
    background-color: #dae0e5;
    border-color: #b8bfc5;
    color: #3c3f43;
}

.pagination__total {
    position: relative;
    font-size: 14px;
    display: block;
    padding: 9px .75rem;
    margin-left: -1px;
    line-height: 1.04;
    color: #c9e2fa;
    background-color: #425f7a;
    border-radius: 0 10% 10% 0;
}