.CaEnseigneYearCard__headerRight{
    display: flex;
    align-items: center;
}

.CaEnseigneYearCard__headerTypes button{
    margin-left: 15px;
}

.CaEnseigneYearCard__headerPeriode {
    margin-left: 15px;
}

.CaEnseigneYearCard__headerPeriode h5{
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}
