/* .mailpreview_logo{
    width: 192px;
    height: 30px;    
    margin-top: 19px;
} */
.mailpreview_logo{
    display: block;
    max-width:192px;
    max-height:30px;
    width: auto;
    height: auto;
}




