.tableActions{
    display: inline-flex;
    justify-content: space-between; 
    align-items: center;
}

.tableActions__title{
    display: flex;
    justify-content: flex-start;
    justify-self: flex-start;
    margin: 20px 5px 0 5px;
}
.tableActions__title >h3{
    display: flex;
    align-self: flex-end;
}
.documentTitle__icon{
    margin-right: 5px;
    margin-bottom: 10px;
    color: rgb(25, 98, 158);
}

.tableActions__icons{
    display: flex;
    justify-self: flex-end;
    justify-content: space-between;
    margin-left: 25px;
}
.tableActions__action{
    display: flex;
    width: 30px;
    height: 29px;
    padding-top: 4px;
    justify-content: center;
    cursor: pointer;
    color: rgb(25, 98, 158);
    border: 1px solid white;
    background: white;
    border-radius: 5px;
    margin-right: 5px;
}
.tableActions__action > button{
    /* background-color:  rgb(37,115,125); */
    background-color: rgb(25, 98, 158);
    border-radius: 50px;
    color: rgb(25, 98, 158);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.tableActions__action >button:hover{
    /* background-color:  rgb(37,115,125); */
    background-color: white;
    color: rgb(25, 98, 158);
    border: 1px solid rgb(25, 98, 158);
}
.tableActions__icon{
    margin: 0 5px;
    font-size: 20px;
}
