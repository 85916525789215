.clientsHeader {
    background: #5ca1e5;
    padding: 7px 10px 3px 5px;
    height: 52px;
}

/* .table tbody {
    display: block;
    overflow: auto;
    max-height: calc(100vh - 88px);
} */