.tableHead {
    display: table;
    width: 100%;
    table-layout: fixed;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 22%);
}
.columnHead{
    background: linear-gradient( rgba(207,224,238,1) 0%, rgba(227,238,247,1) 100%);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.13);
    color: #2a6da4;
}
.columnHead__container{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    font-size: 12px;
    font-weight: 900;
}

.columnHead__title{
    display: flex;
    justify-self: flex-start;
}

.columnHead__sort{
    display: flex;
}