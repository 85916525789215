/* *********************************************************************** */
.header_menu{
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1rem 1rem 0.9rem;
    height: 67px;
    font-size: 1.5rem;
    margin-bottom: 30px;
}
.header_openAndCLose .OAC{
    width: 25px;
    height: 20px;
    border: 2px solid white;
    border-radius: 12px;
    cursor: pointer;
    position: absolute;
    right: -11px;
    top: 0;
}

.header_openAndCLose .OAC:hover{
    border: 1px solid rgb(25, 98, 158) ;
    background-color: white;
}
.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}
.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}
#main {
    transition: margin-left .5s;
    padding: 16px;
}
@media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
  }
  .hideElement {opacity:0.5;display: none;transition: 0.5s; }
  .displayElement {display: initial;transition: 0.5s;}
/* ******************************************************************************************************* */

.header{
    position: sticky;
    justify-content: center;
    top:0;
    align-items: center;
    background-color: rgb(25, 98, 158);
    font-size: 1rem;
    height: 100vh;
    width: 209px;
    box-shadow: 0px 6px 10px -3px rgba(0, 0, 0, 0.13);
    transition: 0.5s;
    z-index: 5;
}
.header__container{
    justify-content: space-between;
    padding: 40px 0;
    /* max-width: 1100px; */
}
.header__logo{
    text-align:center;
    cursor: pointer;
    display: block;
    min-height: 219px;
}

.header__center{
    flex: 1;
    text-align: center;
    height: 80px;
}

.nav__menu{
    display: block;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-left: 1px;
    z-index: 0;
}
.nav__item{
    height: 70px;
    display: block;
}

/*.nav__item:hover {
    border-bottom: 3px solid #FFA618;
    text-decoration: none;
}*/
.nav__Link{
    /* background-color: #022f67; */
    color: white;   
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 0 1rem 1rem;
    height: 49px;
    font-size: 1rem;
}
.nav__Link:hover {
    border-left: 4px solid white;
    background-color: rgb(38, 146, 235);
    color: white;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.nav__link_active {
    /* border-left: 5px solid white; */
    /*color: #F47834;*/
    /* background-color:#154f96; */
    border-left: 4px solid white;
    background-color: rgb(38, 146, 235);
}
.header__right{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.header__right h5 {
    margin: 5px 10px 0 0;
    color: #112732;
    font-size: 1rem; 
}

.header__profil{
    position: relative;
    margin: 0 20px 8px 15px;
}

.header__profile_name {
    display: flex;
    color: white;
    margin: 7px 0 20px 2px;
    align-items: center;
}

.header__profile_name svg:hover {
    color: rgb(38, 146, 235);
    transition: all 0.2s ease-in-out;
}

.header__personIcon{
    color: rgb(38, 146, 235);
    display:inline;
}

.header__personIcon:hover {
    cursor: pointer;
}

.header__profilDropdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background: #ffffff;
    border: 2px solid rgb(25, 98, 158);
    color: rgb(38, 146, 235);
    top: 48px;
    left: -85px;
    padding: 0px 5px;
    border-radius: 5px;
    z-index: 1;
}

.header__profilDropdown:before {
    content: "";
    position: absolute;
    top: -22px;
    left: 92px;
    transform: rotate(-1deg);
    border: 6px solid white;
    border-color: transparent transparent white transparent;
}

.header__profilItem{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    white-space: nowrap;
}

.header__logoutBtn{
    white-space: nowrap;
    color: rgb(25, 98, 158);
    font-size: 1rem;
    font-weight: 600;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.header__logoutBtn:hover {
    color: rgb(38, 146, 235);
    text-decoration: none;
}

.nav__icon {
    margin-right:0.5rem;
    color:white;
    display: inline;
}

.nav__menu ul {
    visibility: hidden;
    position: absolute;
    top: 0px;
    left: 100%;
    z-index: 10;
    width: 100%;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.nav__menu li:hover > ul { visibility: visible; }
.has-sub ul:hover { visibility: visible; }

.nav__menu li.has-sub > span:after {
    content: '»';
    position: absolute;
    right: 1em;
}

.nav__menu ul li.first {
    -webkit-border-radius: 0 3px 0 0;
    -moz-border-radius: 0 3px 0 0;
    border-radius: 0 3px 0 0;
}

.nav__menu ul li.last {
    -webkit-border-radius: 0 0 3px 0;
    -moz-border-radius: 0 0 3px 0;
    border-radius: 0 0 3px 0;
    border-bottom: 0;
}

.nav__menu.align-right { float: right; }

.nav__menu.align-right li { text-align: right; }

.nav__menu.align-right li.has-sub > span:before {
    content: '+';
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -6px;
}

.nav__menu.align-right li.has-sub > span:after { content: none; }

.nav__menu.align-right ul {
    visibility: hidden;
    position: absolute;
    top: -5px;
    left: -100%;
    z-index: 598;
    width: 100%;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.nav__menu.align-right ul li.first {
    -webkit-border-radius: 3px 0 0 0;
    -moz-border-radius: 3px 0 0 0;
    border-radius: 3px 0 0 0;
}

.nav__menu.align-right ul li.last {
    -webkit-border-radius: 0 0 0 3px;
    -moz-border-radius: 0 0 0 3px;
    border-radius: 0 0 0 3px;
}