.DocDld__container{
    position: absolute;
    top: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.DocDld__wrapper{
    background: white;
    padding: 25px;
}
.DocDld__logo{
    display: flex;
    justify-content: center;
    margin: 10px 0 35px 0;
}


.DocDld__title h2{
    font-size: 1.1rem;
    margin-bottom: 20px;
}
.DivDld__btn{
    display: flex;
    justify-content: center;
}
.DivDld__btn a {
    background: #ea5e0b;
    color: white;
    padding: 10px;
    cursor: pointer;
}